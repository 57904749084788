<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--<div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <circle-loader radius="25" />

        <div class="d-flex flex-column text-right">
          <stat-loader />
          <span-md-loader />
        </div>
      </div>-->
      <div
        class="
          d-flex
          align-items-center
          justify-content-between
          card-spacer
          flex-grow-1
        "
      >
        <span
          class="symbol symbol-circle symbol-50 mr-2"
          v-bind:class="symbolClass"
        >
          <span class="symbol-label">
            <i v-bind:class="iconClass + ' ' + iconSource"></i>
          </span>
        </span>

        <div v-if="isLoading" class="d-flex flex-column text-right">
          <span class="spinner spinner-lg spinner-right"></span>
        </div>
        <div v-else-if="isError" class="d-flex flex-column text-right">
          <span class="fas fa-times fa-2x text-danger"></span>
          <span class="text-danger">ERROR</span>
        </div>
        <div v-else class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h2">
            {{ value }}
          </span>
          <span class="text-muted font-weight-bold mt-2">
            {{ label }}
          </span>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
// import CircleLoader from "@/view/content/loader/CircleLoader";
// import SpanMdLoader from "@/view/content/loader/SpanMdLoader";
// import StatLoader from "@/view/content/loader/StatLoader";

export default {
  name: "basicStatWidget",
  // components: {StatLoader, SpanMdLoader, CircleLoader},
  props: {
    value: Number,
    label: String,
    iconType: String,
    iconSource: String,
    isLoading: Boolean,
    isError: Boolean,
  },
  computed: {
    iconClass: function () {
      return "text-" + this.iconType;
    },
    symbolClass: function () {
      return "symbol-light-" + this.iconType;
    },
  },
  mounted() {},
};
</script>
