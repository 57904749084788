import Rainbow from "rainbowvis.js";

const RED = {
  name: "red",
  light: "#FFE3E3",
  base: "#E12D39",
  dark: "#590D12",
};
const ORANGE = {
  name: "orange",
  light: "#FFE8D9",
  base: "#F35627",
  dark: "#742007",
};
const YELLOW = {
  name: "yellow",
  light: "#FFFAEB",
  base: "#E9B949",
  dark: "#6D500D",
};
const LIGHT_GREEN = {
  name: "light-green",
  light: "#EFFCF6",
  base: "#27AB83",
  dark: "#135340",
};
const GREEN = {
  name: "green",
  light: "#E3F9E5",
  base: "#3F9142",
  dark: "#1F4720",
};
const LIGHT_BLUE = {
  name: "light-blue",
  light: "#E3F8FF",
  base: "#2BB0ED",
  dark: "#08435E",
};
const BLUE = {
  name: "blue",
  light: "#E0E8F9",
  base: "#4C63B6",
  dark: "#1E2748",
};
const PURPLE = {
  name: "purple",
  light: "#EEE5FF",
  base: "#8950FC",
  dark: "#230264",
};

export const COLORS = [
  YELLOW,
  ORANGE,
  RED,
  GREEN,
  LIGHT_GREEN,
  LIGHT_BLUE,
  BLUE,
  PURPLE,
];

const ColorService = {
  convertHexToRGBA(hexCode, opacity) {
    let hex = hexCode.replace("#", "");

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  },
  createGradient(startColor, endColor, stepsCount) {
    let gradient = ["rgba(255,255,255,0)"]; // white transparent start

    let rainbow = new Rainbow();
    rainbow.setNumberRange(1, stepsCount - 1);
    rainbow.setSpectrum(startColor, endColor);

    for (let i = 1; i <= stepsCount - 1; i++) {
      let hexColour = this.convertHexToRGBA(rainbow.colourAt(i), 1);
      gradient.push(hexColour);
    }

    return gradient;
  },
  getColorByName(name) {
    return COLORS.find((color) => {
      return color.name === name;
    });
  },
  getTransparentColorByName(name, opacity) {
    return this.convertHexToRGBA(this.getColorByName(name).base, opacity);
  },
};

export default ColorService;
