<script>
import { Bar } from "vue-chartjs";
import ColorService from "../../../services/colors/color.service";
import i18nDiclinaService from "../../../services/diclina_i18n";

export default {
  name: "StatusBarChart",
  extends: Bar,
  props: ["statusGroup"],
  computed: {
    data() {
      let labels = [];
      let label = this.getStatusGroupName(this.statusGroup.name);
      let data = [];
      let backgroundColor = [];
      let borderColor = [];

      for (const status of this.statusGroup.items) {
        const statusColor = i18nDiclinaService.getDiclinaStatusStyle(
          this.statusGroup.name,
          status.name.replace("status_", "")
        ).color;

        labels.push(
          this.getStatusName(
            this.statusGroup.name,
            status.name.replace("status_", "")
          )
        );

        data.push(status.n);

        backgroundColor.push(
          ColorService.getTransparentColorByName(statusColor, 0.2)
        );

        borderColor.push(ColorService.getColorByName(statusColor));
      }

      return {
        labels,
        datasets: [
          {
            label,
            data,
            backgroundColor,
            borderColor,
            borderWidth: 2,
            categoryPercentage: 0.5,
          },
        ],
      };
    },
  },
  data() {
    return {
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    getStatusGroupName: (statusGroup) =>
      i18nDiclinaService.getDiclinaStatusGroupName(statusGroup),
    getStatusName: (statusGroup, status) =>
      i18nDiclinaService.getDiclinaStatusName(statusGroup, status),
    getStatusStyle: (statusGroup, status) =>
      i18nDiclinaService.getDiclinaStatusStyle(statusGroup, status),
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>

<style scoped></style>
