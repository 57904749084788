<template>
  <div>
    <!--begin::dashboard-->
    <status-widgets />
    <!--end::dashboard-->
  </div>
</template>

<script>
import StatusWidgets from "./dashboard/widgets/StatusWidgets";

export default {
  name: "Dashboard",
  components: {
    StatusWidgets,
  },
};
</script>
