<template>
  <div>
    <div v-if="status !== null">
      <div
        v-for="statusGroup in statusGroups"
        :key="statusGroup.name"
        class="mb-12"
      >
        <h4>
          {{ getStatusGroupName(statusGroup.name) }}
          <span class="text-muted">
            ({{ getStatusGroupAddressCount(statusGroup) }} Gesamt)
          </span>
        </h4>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col
                v-for="status in statusGroup.items"
                :key="statusGroup.name + '_' + status.name"
                cols="12"
                lg="6"
              >
                <BasicStatWidget
                  :value="status.n"
                  :label="
                    getStatusName(
                      statusGroup.name,
                      status.name.replace('status_', '')
                    )
                  "
                  :icon-type="
                    getStatusIconStyle(
                      statusGroup.name,
                      status.name.replace('status_', '')
                    )
                  "
                  :icon-source="
                    getStatusIconClass(
                      statusGroup.name,
                      status.name.replace('status_', '')
                    )
                  "
                  :is-loading="false"
                  :is-error="false"
                ></BasicStatWidget>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <StatusBarChart :status-group="statusGroup" />
          </v-col>
        </v-row>
      </div>
      <dashboard-status-product-widget class="mb-12" />
    </div>
  </div>
</template>

<script>
import BasicStatWidget from "../../../components/BasicStatWidget";
import i18nDiclinaService from "../../../services/diclina_i18n";
import StatusBarChart from "../charts/StatusBarChart";
import DashboardStatusProductWidget from "../../../components/DashboardStatusProductWidget";

export default {
  name: "StatusWidgets",
  components: {
    DashboardStatusProductWidget,
    StatusBarChart,
    BasicStatWidget,
  },
  data() {
    return {
      counts: {},
      isLoading: true,
      isError: false,
    };
  },
  computed: {
    status() {
      return this.$store.getters.status;
    },
    statusGroups() {
      return this.status.items.filter((statusGroup) => {
        return statusGroup.name !== "product";
      });
    },
  },
  methods: {
    getStatus(name) {
      return this.status.find((o) => o.name === name);
    },

    getStatusGroupAddressCount: (statusGroup) =>
      statusGroup.items.reduce((a, b) => a + (b.n || 0), 0),

    getStatusGroupName: (statusGroup) =>
      i18nDiclinaService.getDiclinaStatusGroupName(statusGroup),

    getStatusName: (statusGroup, status) =>
      i18nDiclinaService.getDiclinaStatusName(statusGroup, status),

    getStatusStyle: (statusGroup, status) =>
      i18nDiclinaService.getDiclinaStatusStyle(statusGroup, status),

    getStatusIconStyle(statusGroup, status) {
      return this.getStatusStyle(statusGroup, status).color || "";
    },

    getStatusIconClass(statusGroup, status) {
      return (
        this.getStatusStyle(statusGroup, status).iconCssClass ||
        "fas fa-question"
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("updateStatus");
  },
};
</script>

<style scoped></style>
