// DICLINA Translations
const locale = {
  STATUSGROUP: {
    buildconn: {
      de: "Hausanschluss",
      STATUS: {
        open: {
          de: "Noch nicht beauftragt",
          style: {
            color: "red",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "In Auftrag gegeben",
          style: {
            color: "orange",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        pending: {
          de: "In Bau",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-wrench",
          },
        },
        accepted: {
          de: "Fertig gestellt",
          style: {
            color: "green",
            iconCssClass: "fas fa-flag-checkered",
          },
        },
      },
    },
    gnv: {
      de: "GNV",
      STATUS: {
        open: {
          de: "Nicht angeboten",
          style: {
            color: "orange",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Angeboten",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-paper-plane",
          },
        },
        accepted: {
          de: "Abgeschlossen",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
        rejected: {
          de: "Abgelehnt",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
      },
    },
    internet: {
      de: "Hatte zuvor Internet",
      STATUS: {
        open: {
          de: "Hatte Internet",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
        accepted: {
          de: "Hatte kein Internet",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
      },
    },
    ne4: {
      de: "NE4 Bau",
      STATUS: {
        open: {
          de: "Nicht beauftragt",
          style: {
            color: "red",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Teiweise fertiggestellt",
          style: {
            color: "orange",
            iconCssClass: "fas fa-tasks",
          },
        },
        progress: {
          de: "In Bau",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-wrench",
          },
        },
        commissioned: {
          de: "Beauftragt",
          style: {
            color: "light-green",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        completed: {
          de: "Fertiggestellt",
          style: {
            color: "green",
            iconCssClass: "fas fa-flag-checkered",
          },
        },
      },
    },
    product: {
      de: "Produkt",
      STATUS: {
        open: {
          de: "Nicht angeboten",
          style: {
            color: "orange",
            iconCssClass: "fas fa-exclamation",
          },
        },
        opened: {
          de: "Angeboten",
          style: {
            color: "yellow",
            iconCssClass: "fas fa-paper-plane",
          },
        },
        accepted: {
          de: "Unterschrieben",
          style: {
            color: "light-green",
            iconCssClass: "fas fa-clipboard-check",
          },
        },
        rejected: {
          de: "Abgelehnt / Widerrufen",
          style: {
            color: "red",
            iconCssClass: "fas fa-times",
          },
        },
        active: {
          de: "Aktiv",
          style: {
            color: "green",
            iconCssClass: "fas fa-check",
          },
        },
      },
    },
  },
};

export default locale;
